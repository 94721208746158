import React, { useContext } from "react";
import classes from "../../Live.module.css";
import { AppContext } from "./../../context/AppContext";

function SessionDetails() {
  const appContext = useContext(AppContext);
  const { sessionStats, userType } = appContext;
  const timeString = (sec_num) => {
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };
  const setTime = () => {
    // console.log("sessionStats", sessionStats);
    return timeString(sessionStats ? sessionStats : 0);
  };
  return (
    <div className={appContext.userType=='tutor' ? 'tutorSessionInfo' : 'studentSessionInfo'}>
      <div className={classes.tutorName}>
        {appContext.sessionData.tutorName}
      </div>
      <div className={classes.sessionDuration}>{setTime()}</div>
    </div>
  );
}

export default SessionDetails;