import React, { PureComponent } from "react";
import { Tab, Loader, Menu, Icon } from "semantic-ui-react";
import Chat from "./Chat";
import { toast } from "react-toastify";
import Rooms from "./Rooms";
import Settings from "./Settings";
import { AppContext } from "../../context/AppContext";
import AudioRaiseHand from "../../utils/notification.mp3";
import { getStudentsList } from "../../apis";
export default class TabContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      // interval : null
    };
  }

  static contextType = AppContext;

  componentDidMount() {
    // console.log("main chat :: context : ", this.context);
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  setPanes = () => {
    // console.log("setting pane");
    let panes = [{
      key: "chatPane",
      menuItem: (
        <Menu.Item key="chat">
            <Icon name="chat" />
            Chat
          </Menu.Item>
      ),
      pane: {
        key: "chatPane",
        content: (
          <Tab.Pane className="tabPan">
              <Chat />
            </Tab.Pane>
        ),
      },
    }, ];

    if (this.context.userType === "tutor") {
      panes.push({
        menuItem: (
          <Menu.Item key="rooms">
              <Icon name="users" />
              Classroom ({this.context.membersCount})
            </Menu.Item>
        ),
        pane: {
          key: "RoomsPane",
          content: (
            <Tab.Pane className="tabPan">
                <Rooms />
              </Tab.Pane>
          ),
        },
      }, {
        menuItem: (
          <Menu.Item key="settings">
              <Icon name="settings" />
              Settings
            </Menu.Item>
        ),
        pane: {
          key: "settingsPane",
          content: (
            <Tab.Pane className="tabPan">
                <Settings />
              </Tab.Pane>
          ),
        },
      });
    }

    return panes;
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <React.Fragment>
        <audio className="audio-element">
          <source src={AudioRaiseHand}></source>
        </audio>

        <Tab
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          renderActiveOnly={false}
          menu={{
            color: "blue",
            secondary: true,
            pointing: true,
            attached: "top",
          }}
          panes={this.setPanes()}
        />
      </React.Fragment>
    );
  }
}