import { BASE_URL, LIVE_SERVER_URL, LIVE_SERVER_SECRET, LIVE_SOCKET_URL, LIVE_SERVER_BITRATE } from "./appConfig";
import { toast } from "react-toastify";

const headers = (persistedData) => {
  return {
    accept: "application/json",
    "Content-Type": "application/json",
    "Api-Version": 7,
    "x-access-token": persistedData.token,
  };
};

const createSession = (sessionId) => {
  return new Promise((resolve, reject) => {
    fetch(LIVE_SERVER_URL + "/api/sessions", {
        method: 'POST',
        headers: {
          "Authorization": "Basic " + btoa("OPENVIDUAPP:" + LIVE_SERVER_SECRET),
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ customSessionId: sessionId })
      })
      .then(response => response.json())
      .then((response) => resolve(response.id))
      .catch((error) => {
        resolve(sessionId);
      });
  });
}

export const getSessionDetails = (data) => {
  return new Promise((resolve, reject) => {
    fetch(LIVE_SOCKET_URL + "/v1/live/getSessionDetails", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
}
export const startRecording = (data) => {
  return new Promise((resolve, reject) => {
    fetch(LIVE_SOCKET_URL + "/v1/live/startRecording", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
}

export const stopRecording = (data) => {
  return new Promise((resolve, reject) => {
    fetch(LIVE_SOCKET_URL + "/v1/live/stopRecording", {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
}

const createToken = (sessionId) => {
  return new Promise((resolve, reject) => {
    fetch(LIVE_SERVER_URL + "/api/tokens", {
        method: 'POST',
        headers: {
          "Authorization": "Basic " + btoa("OPENVIDUAPP:" + LIVE_SERVER_SECRET),
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          session: sessionId,
          "kurentoOptions": {
            "allowedFilters": ["GStreamerFilter", "FaceOverlayFilter", "ChromaFilter"],
            "videoMaxRecvBandwidth": LIVE_SERVER_BITRATE,
            "videoMinRecvBandwidth": LIVE_SERVER_BITRATE,
            "videoMaxSendBandwidth": LIVE_SERVER_BITRATE,
            "videoMinSendBandwidth": LIVE_SERVER_BITRATE
          }
        }),
      })
      .then(response => response.json())
      .then((response) => resolve(response.token))
      .catch((error) => reject(error))
  });
}

export const getVideoToken = (sessionId) => {
  return createSession(sessionId).then(sessionId => createToken(sessionId));
}

export const startSession = (sessionData, persistedData) => {
  console.log("^^^^^sessionData", sessionData);

  let payload = {
    isExisting: sessionData.isExistingSession ? sessionData.isExistingSession : 0,
    streamKey: sessionData.streamKey
  }
  fetch(
      `${BASE_URL}/v2/live/start/${sessionData.liveSessionId}`, {
        method: "PUT",
        headers: headers(persistedData),
        body: JSON.stringify(payload)
      }
    )
    .then((data) => {
      // console.log("data", data);
    })
    .catch((err) => {
      alert("Error while initiating start");
      console.error("error-=-=-\n", err);
    });
};

export const leaveChannel = (sessionData, persistedData, handler) => {
  fetch(
      `${BASE_URL}/v2/live/end/${sessionData.liveSessionId}`, {
        method: "PUT",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Api-Version": 14,
          "x-access-token": persistedData.token,
        },
      }
    )
    .then((data) => {
      window.top.postMessage("CLOSE_WINDOW", "*");
      if (typeof handler == "function") {
        handler();
      }
      console.log("data", data);
    })
    .catch((err) => {
      window.top.postMessage("CLOSE_WINDOW", "*");
      console.error("error-=-=-\n", err);
    });
};

export const getStudentsList = (sessionData, persistedData) => {
  let { type, entityId, batchCode } = sessionData;

  let batchStudentsUrl = batchCode ? `${BASE_URL}/v2/batches/${batchCode}/students?limit=20000&offset=0&type=current` : ``;
  let courseStudentsUrl = entityId ? `${BASE_URL}/v2/course/students/${entityId}?limit=20000&offset=0` : '';

  let studentsUrl = type == 3 ? batchStudentsUrl : courseStudentsUrl;
  return new Promise((resolve, reject) => {
    fetch(`${studentsUrl}`, {
        method: "GET",
        headers: headers(persistedData),
      })
      .then((response) => response.json())
      .then((response) => resolve(response.data))
      .catch((err) => {
        reject(err);
      });
  });
};