import React, { PureComponent, useEffect, useContext } from "react";
import ChatInputWithButtons from "../../components/chatInputWithButtons";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import _ from "lodash";

import classes from "../../Live.module.css";
import VideoClient from "../VideoPanel/videoHandlers";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import ChatMessage from "../../components/chatMessage";
import RtmConnectionStatus from "../../components/RtmConnectionStatus";
export default class Chat extends PureComponent {
  static contextType = AppContext;
  videoClient = undefined;

  constructor() {
    super();

    this.state = {
      chats: [],
      connectionState: null,
      connectionStateReason: null,
      isInputAllowedForStudent: true,
    };
    this.chatContainerContentRef = React.createRef();

    var self = this;
    setTimeout(function() {
      self.videoClient = self.context.videoClient;
      // console.log("Chat :: videoClient : ", self.videoClient);
      let { sessionData, persistedData } = self.context;
      console.log("Chat :: sessionData : ", sessionData);

      // self.videoClient.socket.on("update_chat", data => {
      //   console.log("Chat :: update_chat : data : ", data);
      //   if (sessionData.liveSessionId == data.sessionId) {
      //     let chat = {
      //       _id: data._id,
      //       message: data.message,
      //       type: "MESSAGE",
      //       user: data.user,
      //       sent_at: moment().format("HH:mm")
      //     }
      //     self.setState({ chats: [...self.state.chats, chat] });
      //   }
      // })
      self.videoClient.socket.on("update_chats", async (data) => {
        console.log("Chat :: update_chats : data : ", data);
        if (sessionData.liveSessionId == data.sessionId) {
          for (var i = 0; i < data.msgs.length; i++) {
            var msg = data.msgs[i];
            if (persistedData.user.id != msg.user.id) {
              let chat = {
                _id: msg._id,
                message: msg.message,
                type: "MESSAGE",
                user: msg.user,
                sent_at: moment().format("HH:mm")
              }
              self.setState({ chats: [...self.state.chats, chat] });
            }
            await self.sleep(1000 / data.msgs.length);
          }

          self.setState({ chats: _.takeRight(self.state.chats, 100) });
        }
      });
      self.videoClient.on("chat_msgs", msgs => {
        console.log("Chat :: chat_msgs : msgs : ", msgs);
        var chats = [];
        msgs.forEach(function(data, i) {
          let chat = {
            _id: data._id,
            message: data.message,
            type: "MESSAGE",
            user: data.user,
            sent_at: data.sent_at
          }
          chats.push(chat);
        })
        self.setState({ chats: chats });
        setTimeout(function() {
          var myDiv = document.getElementById("chat-container");
          myDiv.scrollTop = myDiv.scrollHeight;
        }, 2000);
      })

      self.videoClient.socket.on("student_events", (data) => {
        console.log(data, "++++toDisableInput");
        let { persistedData } = self.context;
        if (data.eventType == 98 && persistedData.user.type == 1) {
          if (data.isAllowed == false) {
            self.setState({
              isInputAllowedForStudent: false,
            });
          } else {
            self.setState({
              isInputAllowedForStudent: true,
            });
          }
        }
        //eventType 98 & data.userType == 3
        // data.isAllowed
      });

    }, 1500);
  }

  sleep = async (seconds) => {
    return new Promise((resolve, reject) => {
      setTimeout(function() {
        resolve({});
      }, seconds)
    })
  }
  onSubmitHandler = (text, clearText) => {
    console.log("Chat ::: text input : " + text);

    let user = this.context.getUserInfo();
    let { sessionData } = this.context;
    let chat = {
      _id: uuidv4(),
      sessionId: sessionData.liveSessionId + "",
      message: text,
      user: {
        id: user.id,
        name: user.name,
        imageUrl: user.imageUrl,
      },
      sent_at: moment().format("HH:mm")
    }
    console.log("Chat ::: chat object : ", chat);
    this.videoClient.sendChat(chat);

    chat.type = "MESSAGE";
    this.setState({ chats: [...this.state.chats, chat] });

    clearText();
  };

  componentDidMount() {}

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.messages && prevProps.messages.length)
      return {
        scrollHeight: this.chatContainerContentRef.current.scrollHeight,
        scrollTop: this.chatContainerContentRef.current.scrollTop,
        clientHeight: this.chatContainerContentRef.current.clientHeight,
      };
    else
      return {
        scrollHeight: 0,
        scrollTop: 0,
        clientHeight: 0,
      };
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    let { scrollHeight, scrollTop, clientHeight } =
    this.chatContainerContentRef.current || {};

    if (
      snapShot.scrollHeight - snapShot.scrollTop - snapShot.clientHeight <
      100 &&
      scrollHeight - scrollTop - clientHeight < 200
    ) {
      this.chatContainerContentRef.current.scrollTop = scrollHeight;
    }
  }

  render() {
    let { chats, connectionState, connectionStateReason, isInputAllowedForStudent } = this.state;
    let user = this.context.getUserInfo();

    let isInputDisabled = false;
    if (user.isTutor == "0") {
      if (isInputAllowedForStudent == false) {
        isInputDisabled = true;
      }
    }
    // console.log(
    //   isInputDisabled,
    //   "******isInputDisabled",
    //   user,
    //   "user---",
    //   isInputAllowedForStudent,
    //   "isInputAllowedForStudent"
    // );

    return (
      <div className={classes.chatContainerContent}>
      <div className={classes.chatContainerItem}>
        <ul
        id="chat-container"
          className={classes.messageContainer}
          ref={this.chatContainerContentRef}
        >
          {chats
            ? chats.map((chat) => (
              <ChatMessage chat={chat} key={chat._id} user={user} />
            ))
            : ""}
        </ul>
      </div>
      {isInputAllowedForStudent == false ? (
          <div
            style={{
              backgroundColor: "rgba(255,64,88,0.2)",
              textAlign: "center",
            }}
          >
            Your chats have been disabled by the tutor
          </div>
        ) : (
          ""
        )}
      {isInputAllowedForStudent && (<div className={classes.chatContainerFooter}>
        <ChatInputWithButtons
          disabled={!isInputAllowedForStudent}
          onSubmitHandler={this.onSubmitHandler}
          isInputAllowedForStudent={isInputAllowedForStudent}
        />
      </div>)}
    </div>
    );
  }
}