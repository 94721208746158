import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  createRef,
} from "react";
import classes from "./VideoPanel.module.css";
import { AppContext } from "./../../context/AppContext";
import VideoClient from "./videoHandlers";
import LiveInfo from "./LiveInfo";
import ConnectingState from "./connecting";
import { startSession, leaveChannel, getStudentsList } from "../../apis";
import { subscribeToEvents } from "./streamHandlers";
import { toast } from "react-toastify";


export default React.memo(function VideoPanel(props) {
  const appContext = useContext(AppContext);

  let mainRef = useRef();
  let sideVideoRef = useRef();
  let mainScreenRef = useRef();
  let [connectionState, setConnectionState] = useState("CONNECTING");
  let [activeStudentContainer, showStudentVideos] = useState(false);
  let [viewsButton, showViewsButton] = useState(false);
  //
  // for testing
  window.appContext = appContext;
  let { sessionType, twoWayEnabled } = appContext;

  useEffect(() => {
    console.log("appContext", appContext);
    let videoClient = new VideoClient();
    appContext.setContext({ videoClient });

    const url = new URL(document.location.href);
    let userType = url.searchParams.get("userType");
    // console.log("VideoClient ::: userType :: ", userType);
    let sessionId = url.searchParams.get("sessionId");
    // console.log("VideoClient ::: sessionId :: ", sessionId);
    let uid = url.searchParams.get("uid");
    // console.log("VideoClient ::: uid :: ", uid);
    let name = url.searchParams.get("name");
    // console.log("VideoClient ::: name :: ", name);
    const { sessionData, persistedData } = appContext;
    sessionType = "ams";
    var type = sessionData.liveSessionType;
    if (type) {
      sessionType = type;
    }
    appContext.setContext({ sessionType: sessionType });


    if (userType) {
      appContext.setContext({ userType: userType });
    }
    if (sessionId) {
      sessionData.liveSessionId = sessionId;
      appContext.setContext({ sessionData: sessionData });
    }

    if (uid && name) {
      persistedData.user.id = uid;
      persistedData.user.name = name;
      persistedData.user.type = userType == "tutor" ? 3 : 1;
      appContext.setContext({ persistedData: persistedData });
    }
  }, []);

  const startTimer = () => {
    var self = this;
    const { videoClient, timerInterval, setContext, sessionStats, sessionData, persistedData, userType } = appContext;
    console.log("VideoPanel ::: startTimer ::  timerInterval : ", timerInterval)
    if (!timerInterval && !sessionStats) {
      let Duration = 0;
      let timeOutWarned = false;
      let timedOut = false;
      let timerInterval1 = setInterval(() => {
        // console.log("Durartion-=-=-=", Duration);
        appContext.setContext({
          sessionStats: Number(Duration ? Duration : 0)
        });
        Duration = Duration + 1;

        if (userType == "tutor" && sessionData.expectedDuration && sessionData.expectedDuration > 300) {
          if (Duration > (sessionData.expectedDuration - 300) && !timeOutWarned) {
            toast.error("Live Session will be ending in 5 more minutes");
            timeOutWarned = true;
          } else if (Duration > sessionData.expectedDuration && !timedOut) {
            toast.error("Live Session is ending after crossing expected scheduled duration.");
            timedOut = true;
            setTimeout(function() {
              videoClient.endAMSSession(userType);
            }, 5000);
          }
        }
      }, 1000);
      setContext({ timerInterval: timerInterval1 });
    }
  };
  useEffect(() => {
    let {
      videoClient,
      sessionData,
      persistedData,
      userType,
      setContext
    } = appContext;
    // console.log("video client", videoClient);
    if (videoClient) {
      setTimeout(function() {
        videoClient.socket.on("student_joined", data => {
          console.log("VideoPanel :: student_joined : data : ", data);
          if (sessionData.liveSessionId == data.sessionId) {
            let { members } = appContext;
            members[data.userId] = {
              id: data.userId,
              name: data.name,
              imageUrl: data.imageUrl,
              joinedAt: data.joinedAt,
              isTutor: data.isTutor,
              platform: data.platform
            }
            setContext({
              members: members,
              membersCount: Object.keys(members).length + 1
            });
          }
        })

        videoClient.socket.on("student_left", data => {
          console.log("VideoPanel :: student_left : data : ", data);
          if (sessionData.liveSessionId == data.sessionId) {
            let { members } = appContext;
            delete members[data.userId || data.uid];
            setContext({
              members: members,
              membersCount: Object.keys(members).length + 1
            });
          }
        })
      }, 2500);
      videoClient.on("session_students", students => {
        console.log("VideoPanel :: session_students : students : ", students);
        let { members } = appContext;
        students.forEach(function(data, i) {
          members[data.userId] = {
            id: data.userId,
            name: data.name,
            imageUrl: data.imageUrl,
            joinedAt: data.joinedAt,
            isTutor: data.isTutor,
            platform: data.platform
          }
        })
        setContext({
          members: members,
          membersCount: Object.keys(members).length + 1
        });
      })
      videoClient.on("screenshare-cancelled", function() {
        var isVideoSharing = localStorage.getItem("videoDisabled") ? true : false;
        appContext.setContext({
          isScreenSharing: false,
          isVideoSharing: isVideoSharing
        });
      });
      videoClient.on("restore-audio-video-controls", evt => {
        setTimeout(function() {
          let isVideoSharing = localStorage.getItem("videoDisabled") ? true : false;
          let micStatus = localStorage.getItem("audioDisabled") ? true : false;
          console.log("VideoPanel ::: restore-audio-video-controls :: ", isVideoSharing, micStatus);
          if (isVideoSharing) {
            appContext.setContext({
              isVideoSharing: isVideoSharing
            });
            videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.turnOffLocalCamera();
          }
          if (micStatus) {
            appContext.setContext({
              micStatus: micStatus
            });
            videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.muteLocalMic();
          }
        }, 100)
      });
      videoClient.on("session-connected", evt => {
        setConnectionState("CONNECTED");
        startTimer();
      });
      videoClient.on("session-ended", evt => {
        setConnectionState("ENDED");
        let { timerInterval } = appContext;
        clearInterval(timerInterval);
      });
      videoClient.on("twoway-enabled", evt => {
        setContext({ twoWayEnabled: evt });
      });
      videoClient.on("session-type", evt => {
        setContext({ sessionType: evt });
      });
      videoClient.on("cameraDevicesList", evt => {
        setContext({ cameraDevicesList: evt });
        console.log("cameraDevicesList", evt);
      });
      videoClient.on("micDevicesList", evt => {
        setContext({ micDevicesList: evt });
        console.log("micDevicesList", evt);
      });
      videoClient.on("showModal", evt => {
        if (evt.type == "DYNAMIC_KEY_EXPIRED") {
          appContext.setContext({
            modal: { type: "DYNAMIC_KEY_EXPIRED", hidden: false }
          });
        }
      });
      videoClient.on("defaultIds", evt => {
        console.log("defaultIds", evt);
        if (evt.type == "camera") {
          setContext({
            activeCameraId: evt.deviceId
          });
        } else if (evt.type == "mic") {
          setContext({
            activeMicId: evt.deviceId
          });
        }
      });
      videoClient.on("client-banned", evt => {
        toast.info("The live class has been ended");
        appContext.setContext({
          modal: { type: "CLIENT_BANNED", hidden: false }
        });
      });
      videoClient.on("VideoError", evt => {
        toast.error("Not Able to access media");
        appContext.setContext({
          modal: {
            type: evt.msg == "NotAllowedError" ? "NotAllowedError" : "Error",
            hidden: false
          }
        });
      });

      videoClient.on("network-quality", stats => {
        console.log("downlinkNetworkQuality", stats.downlinkNetworkQuality);
        console.log("uplinkNetworkQuality", stats.uplinkNetworkQuality);
        appContext.setContext({
          downlinkNetworkQuality: stats.downlinkNetworkQuality,
          uplinkNetworkQuality: stats.uplinkNetworkQuality
        });
      });
      videoClient.init(sessionData, persistedData, userType);
    }

  }, [appContext.videoClient]);

  const getAmsVideoClasses = () => {
    let names = classes.mainVideo;
    if (!twoWayEnabled) {
      names = classes.hide;
    }
    return names;
  }
  const getAmsPlayerClasses = () => {
    let names = classes.mainVideo;
    if (twoWayEnabled) {
      names = classes.hide;
    }
    return names;
  }

  return (
    <React.Fragment>
      {connectionState === "CONNECTING" && <ConnectingState />}
      <div className={classes.VideoPanel}>

        {sessionType === "ams" && (
          <video id="tutorVideo" autoPlay muted className={getAmsVideoClasses()} />
        )}
        {sessionType === "ams" && (
          <div id="player-container" className={getAmsPlayerClasses()}>
            <video id="player" autoPlay />
          </div>
        )}
        {sessionType === "ams" && (
          <video id="audioStream" autoPlay muted className={classes.hide} />
        )}
        {sessionType === "vidu" && (
          <div
            onClick={(e) => {
              console.log(e);
            }}
            ref={mainRef}
            id="mainVideo"
            className={classes.mainVideo}
          ></div>
        )}
        <LiveInfo />
      </div>
    </React.Fragment>
  );
});