import React, { useContext } from "react";
import classes from "../../Live.module.css";
import SVGPlay from "../../images/SVGPlay.svg";
import SVGAttendees from "../../images/SVGAttendees.svg";
import { AppContext } from "./../../context/AppContext";
import { IconExcellent, IconGood, IconBad } from "../../utils/Icons";

function LiveInfo() {
  const appContext = useContext(AppContext);
  const {
    membersCount,
    sessionStats,
    sessionData,
    isVideoSharing,
    downlinkNetworkQuality,
    uplinkNetworkQuality,
    userType
  } = appContext;

  const networkStatus = () => {
    let status = isVideoSharing ? uplinkNetworkQuality : downlinkNetworkQuality;
    if (status == 1) return <IconExcellent />;
    else if (status == 2) return <IconGood />;
    else if (status >= 3 || !status) return <IconBad />;
  };
  // <div className="tooltip">
  //        <div className={classes.attendees} style={{ padding: "3px 6px" }}>
  //          {networkStatus()}
  //        </div>
  //        <div className="tooltiptext">Network Status</div>
  //      </div>
  return (
    <div className={classes.buttonsContainer}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="recording"></div>
        <div className={classes.tutorName} style={{ marginLeft: "8px" }}>
          {sessionData.title}
        </div>
      </div>

      <div className={classes.extraInfo}>
        {(
          <div className={classes.attendees}>
            <span className={classes.hideMob}>
              <img src={SVGAttendees} style={{ "margin-right": "5px" }} />
              &nbsp;
            </span>
            <span className={classes.liveCount}>
              {membersCount > 0 ? membersCount: 0}
            </span>
          </div>
        )}

        <div className={classes.liveNowRed}>
          <img src={SVGPlay} style={{ "margin-right": "5px" }} />
          LIVE
        </div>
      </div>
    </div>
  );
}

export default LiveInfo;