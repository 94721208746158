import React, { PureComponent } from "react";
import { AppContext } from "../../context/AppContext";
import RoomUserCard from "../../components/roomUserCard";
import staticMembers from "../../staticRoomMembers";

import { v4 as uuidv4 } from "uuid";

export default class Rooms extends PureComponent {
  static contextType = AppContext;
  state = {
    members: []
  };

  // component did mount
  componentDidMount() {
    console.log("rooms");
    var self = this;
    setTimeout(function() {
      let { setContext, videoClient, sessionData, persistedData, userType } = self.context;
      console.log("Rooms :: sessionData : ", sessionData);
      console.log("Rooms :: userType : ", userType);

      if (videoClient) {
        videoClient.socket.on("student_events", function(data) {
          console.log("RoomUserCard ::: student_events :: data : ", data);
          if (data.event === "REQUESTED") {
            let { members, membersCount } = self.context;
            if (members[data.userId])
              members[data.userId].invitationStatus = "PENDING";
            setContext({
              members: members,
            });
            if (self.context && self.context.raiseHandSettings) {
              const audioEl = document.getElementsByClassName(
                "audio-element"
              )[0];
              audioEl.play();
            }
          } else if (data.event === 'ENDED') {
            let { members, membersCount } = self.context;
            if (members[data.userId]) members[data.userId].invitationStatus = "ENDED";
            setContext({
              members: members
            });
          } else if (data.event === 'CONNECTED') {
            let { members, membersCount } = self.context;
            if (members[data.userId]) members[data.userId].invitationStatus = "CONNECTED";
            setContext({
              members: members
            });
          }
        })
      }
    }, 2500);

  }

  render() {
    let { members = {}, getUserInfo, userType } = this.context;

    let user = getUserInfo();
    return (
      <div className="roomsTab">
        <ul className="roomsList">
          {userType == 'tutor' && <RoomUserCard
                  key={user.id}
                  member={user}
                  tutorId={user.id}
                  memberId={user.id}
                />}
          {members
            ? Object.keys(members).map((memberId) => (
                <RoomUserCard
                  key={memberId}
                  member={members[memberId]}
                  tutorId={user.id}
                  memberId={memberId}
                />
              ))
            : ""}
        </ul>
      </div>
    );
  }
}