import React, { useState } from "react";
import Proptypes from "prop-types";
import { Input, Loader } from "semantic-ui-react";
import classes from "../Live.module.css";
import SVGSend from "../images/SVGSend.svg";

export default function ChatInputWithButtons(props) {
  let [text, setText] = useState("");
  function inputChangeHandler(e) {
    setText(e.target.value);
  }

  function clearInput() {
    setText("");
  }

  function keyPress(e) {
    if (e.keyCode === 13) {
      onsubmit();
    }
  }

  function onsubmit() {
    if (typeof props.onSubmitHandler !== "function") return;
    if (text.trim().length === 0) return false;
    props.onSubmitHandler(text, clearInput);
  }

  return (
    <Input
      type="text"
      fluid
      placeholder="Write something here …"
      onChange={inputChangeHandler}
      onKeyDown={keyPress}
      value={text}
    >
      <input className={classes.chatMessageInput} disabled={props.disabled} />
      <button
        onClick={onsubmit}
        className={classes.btnMessageSend}
        disabled={props.disabled}
      >
       {props.disabled ? (
          props.isInputAllowedForStudent ? (
            <Loader active size="small" inline />
          ) : (
            ""
          )
        ) : (
          <img src={SVGSend} style={{ height: "20px", width: "20px" }} />
        )}
      </button>
    </Input>
  );
}

ChatInputWithButtons.propTypes = {
  onSubmitHandler: Proptypes.func.isRequired,
};
