const data = {
  289994: {
    id: 289994,
    name: "Gunjan",
    imageUrl:
      "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/single/learn/b232c03a-aea1-4e29-b79c-a7897527b2fa.png",
    platform: "web",
    isTutor: "1",
  },
  543623: {
    id: 543623,
    name: "APOORV GUPTA",
    imageUrl:
      "https://res.cloudinary.com/classplus/image/upload/q_auto/v1556894413/profile_images/q4iofmljkc2bbvxactwm.jpg",
    joinedAt: "2020-12-11T10:15:30.362Z",
  },
  543624: {
    id: 543624,
    name: "APOORV2",
    imageUrl:
      "https://res.cloudinary.com/classplus/image/upload/q_auto/v1556894413/profile_images/q4iofmljkc2bbvxactwm.jpg",
    joinedAt: "2020-12-11T10:15:31.362Z",
  },
  543624: {
    id: 543625,
    name: "APOORV3",
    imageUrl:
      "https://res.cloudinary.com/classplus/image/upload/q_auto/v1556894413/profile_images/q4iofmljkc2bbvxactwm.jpg",
    joinedAt: "2020-12-11T10:15:32.362Z",
  },
};

export default data;
