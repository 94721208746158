import React, { useContext, useEffect, useState } from "react";
import classes from "./Controls.module.css";
import Button from "../../components/Button/Button";
import { AppContext } from "./../../context/AppContext";
import { leaveChannel } from "../../apis";
import Dropdown from "../../utils/Dropdown/Dropdown";
import CloseModal from "./CloseModal";
import LeaveForced from "./LeaveForced";
import { toast } from "react-toastify";

import StudentRaiseHandControls from "../../components/StudentRaiseHandControls";
import {
  IconDropdown,
  IconMic,
  IconVideo,
  IconScreenshare,
  IconVideoDisable,
  IconMicDisable,
  IconRotate,
} from "../../utils/Icons";

export default function Controls(props) {
  const enableHandRaise = false;
  const appContext = useContext(AppContext);
  const {
    localStreams,
    activeStreamId,
    userType,
    studentInvitation,
    cameraDevicesList,
    micDevicesList,
    activeCameraId,
    activeMicId,
    setContext,
    defaultIds,
    videoClient
  } = appContext;
  let activeStream;
  if (localStreams) {
    activeStream = localStreams[activeStreamId];
  }

  useEffect(() => {
    appContext.setContext({
      modal: {
        hidden: true,
        type: "",
      },
    });

  }, []);

  useEffect(() => {
    // appContext.setContext({
    // micStatus: false,
    // isVideoSharing: false,
    // });

    let { videoClient, isScreenSharing } = appContext;
    // console.log("Controls ::: isScreenSharing change : ", videoClient);
    if (videoClient) {
      toast.dismiss();
      if (videoClient.sessionType == "ams") {
        videoClient.shareAmsScreenChanged(isScreenSharing);
      } else if (videoClient.sessionType == "vidu") {
        videoClient.shareViduScreenChanged(isScreenSharing);
      }
    }
  }, [appContext.isScreenSharing]);

  useEffect(() => {
    if (!videoClient) return;
    if (videoClient.publisher || videoClient.webRTCAdaptor)
      if (appContext.micStatus) {
        videoClient.publisher && videoClient.publisher.publishAudio(false);
        videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.muteLocalMic();
        toast.dismiss();
        toast.error("Mic Disabled", { autoClose: 3600 * 1000 });
        videoClient.socket.emit("student_events", {
          userId: appContext.sessionData.uid,
          sessionId: appContext.sessionData.liveSessionId,
          event: "MicDisabled",
          eventType: 108,
          isAllowed: true
        })
      } else {
        videoClient.publisher && videoClient.publisher.publishAudio(true);
        videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.unmuteLocalMic();
        toast.dismiss();
        toast.success("Mic Enabled");
        videoClient.socket.emit("student_events", {
          userId: appContext.sessionData.uid,
          sessionId: appContext.sessionData.liveSessionId,
          event: "MicEnabled",
          eventType: 108,
          isAllowed: false
        })
      }
  }, [appContext.micStatus]);

  useEffect(() => {
    if (!videoClient) return;
    if (videoClient.publisher || videoClient.webRTCAdaptor) {
      if (appContext.isVideoSharing) {
        if (localStorage.getItem("videoDisabled")) {
          videoClient.publisher && videoClient.publisher.publishVideo(false);
          videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.turnOffLocalCamera();
          toast.dismiss();
          toast.error("Video Disabled", { autoClose: 3600 * 1000 });
          videoClient.socket.emit("student_events", {
            userId: appContext.sessionData.uid,
            sessionId: appContext.sessionData.liveSessionId,
            event: "VideoDisabled",
            eventType: 106,
            isAllowed: true
          })
        }
      } else {
        toast.dismiss();
        toast.success("Video Enabled");
        videoClient.publisher && videoClient.publisher.publishVideo(true);
        videoClient.webRTCAdaptor && videoClient.webRTCAdaptor.turnOnLocalCamera();
        videoClient.socket.emit("student_events", {
          userId: appContext.sessionData.uid,
          sessionId: appContext.sessionData.liveSessionId,
          event: "VideoEnabled",
          eventType: 106,
          isAllowed: false
        })
      }
    }
  }, [appContext.isVideoSharing]);


  useEffect(() => {
    let { sessionData } = appContext;
    if (!videoClient) return;
    if ((videoClient.publisher || videoClient.webRTCAdaptor) && appContext.activeCameraId && appContext.activeMicId) {
      videoClient.deviceChanged(null, appContext.activeMicId);
      localStorage.setItem("sessionId", sessionData.liveSessionId);
      localStorage.setItem("activeMicId", appContext.activeMicId);

      if (localStorage.getItem("audioDisabled")) {
        var micStatus = localStorage.getItem("audioDisabled") ? true : false;
        appContext.setContext({
          micStatus: !micStatus
        });
        localStorage.removeItem("audioDisabled");
      }
    }
  }, [appContext.activeMicId]);

  useEffect(() => {
    let { sessionData } = appContext;
    if (!videoClient) return;
    if ((videoClient.publisher || videoClient.webRTCAdaptor) && appContext.activeCameraId && appContext.activeMicId) {
      videoClient.deviceChanged(appContext.activeCameraId, null);
      localStorage.setItem("sessionId", sessionData.liveSessionId);
      localStorage.setItem("activeCameraId", appContext.activeCameraId);

      if (localStorage.getItem("videoDisabled")) {
        var isVideoSharing = localStorage.getItem("videoDisabled") ? true : false;
        appContext.setContext({
          isVideoSharing: !isVideoSharing
        });
        localStorage.removeItem("videoDisabled");
      }
    }
  }, [appContext.activeCameraId]);

  useEffect(() => {
    if (appContext.leaveClass)
      leaveChannel(appContext.sessionData, appContext.persistedData, () => {
        appContext.setContext({
          showLoader: true,
        });
        toast.success("Live session ended");
      });
  }, [appContext.leaveClass]);

  const handleScreenSharing = () => {
    if (appContext.isVideoSharing) {
      toast.error("Please enable video to ShareScreen");
    } else {
      appContext.setContext({
        isScreenSharing: !appContext.isScreenSharing,
      });
    }
  };
  const handleMicClick = () => {
    let { sessionData } = appContext;
    console.log("Controls ::: handleMicClick :: isVideoSharing : ", appContext.isVideoSharing, appContext.micStatus)
    if (appContext.micStatus) {
      localStorage.removeItem("audioDisabled");
    } else {
      localStorage.setItem("sessionId", sessionData.liveSessionId);
      localStorage.setItem("audioDisabled", true);
    }
    appContext.setContext({
      micStatus: !appContext.micStatus,
    });
  };
  const handleVideoClick = () => {
    let { sessionData } = appContext;
    if (appContext.isVideoSharing) {
      localStorage.removeItem("videoDisabled");
    } else {
      localStorage.setItem("sessionId", sessionData.liveSessionId);
      localStorage.setItem("videoDisabled", true);
    }
    appContext.setContext({
      isVideoSharing: !appContext.isVideoSharing,
    });
  };

  const confirmation = (type = "", hidden = true) => {
    // appContext.setContext({
    //   modal: { type, hidden },
    // });
    let { videoClient, userType, sessionType } = appContext;
    if (videoClient) {
      if (sessionType == "ams") {
        videoClient.endAMSSession(userType);
      } else if (sessionType == "vidu") {
        videoClient.endViduSession(userType);
      }
    }
  };

  let [current_rotation, rotateDiv] = useState(90);

  const rotateClick = () => {
    rotateDiv(current_rotation + 90);
    document.querySelector("#mainVideo").firstElementChild.style.transform =
      "rotate(" + current_rotation + "deg)";
  };

  // console.log("Controls :: ", appContext)

  return (
    <div className={classes.lowerUiBarActions +  " " + (appContext.userType != "tutor" ? 'justify-center' : '')}>
      <React.Fragment>
        {((studentInvitation && studentInvitation.status == "accepted") ||
          userType === "tutor") && (
            <>
              {userType == "tutor" && (
                <Button onClick={handleScreenSharing} className={classes.Button}>
                  <IconScreenshare color="#333333" />
                  <div style={{ marginLeft: "4px" }}>
                    {!appContext.isScreenSharing
                      ? "Share Screen"
                      : "Stop Screen Sharing"}
                  </div>
                </Button>
              )}
              <div className={classes.Button}>
                <Button onClick={handleMicClick} className={classes.commonBtn}>
                  {!appContext.micStatus ? (
                    <IconMic color="#333333" />
                  ) : (
                      <IconMicDisable color="#333333" />
                    )}
                </Button>
                <Dropdown
                  name={<IconDropdown color="#333333" />}
                  ddnClass={classes.DropdownItems}
                  noCaret={true}
                  items={
                    <React.Fragment>
                      {micDevicesList &&
                        micDevicesList.length &&
                        micDevicesList.map((mic) => {
                          return (
                            <li
                              className={
                                classes.listItems +
                                " " +
                                (activeMicId === mic.deviceId
                                  ? classes.selected
                                  : "")
                              }
                              onClick={() => {
                                setContext({
                                  activeMicId: mic.deviceId,
                                });
                              }}
                            >
                              {mic.label}
                            </li>
                          );
                        })}
                    </React.Fragment>
                  }
                />
              </div>

              {(!appContext.isScreenSharing && (appContext.sessionData.showVideo || userType == "tutor")) && (
                <div className={classes.Button}>
                  <Button
                    onClick={handleVideoClick}
                    className={classes.commonBtn}
                  >
                    {!appContext.isVideoSharing ? (
                      <IconVideo color="#333333" />
                    ) : (
                        <IconVideoDisable color="#333333" />
                      )}
                  </Button>
                  <Dropdown
                    name={<IconDropdown color="#333333" />}
                    ddnClass={classes.DropdownItems}
                    noCaret={true}
                    items={
                      <React.Fragment>
                        {cameraDevicesList &&
                          cameraDevicesList.length &&
                          cameraDevicesList.map((camera) => {
                            return (
                              <li
                                className={
                                  classes.listItems +
                                  " " +
                                  (activeCameraId === camera.deviceId
                                    ? classes.selected
                                    : "")
                                }
                                onClick={() => {
                                  setContext({
                                    activeCameraId: camera.deviceId,
                                  });

                                }}
                              >
                                {camera.label}
                              </li>
                            );
                          })}
                      </React.Fragment>
                    }
                  />
                </div>
              )}
            </>
          )}
        {userType === "student" && enableHandRaise && <StudentRaiseHandControls />}
        {userType === "student" && enableHandRaise && !appContext.sessionData.isHostedOnWeb && (
          <Button onClick={rotateClick} className={classes.Button}>
            <IconRotate />
          </Button>
        )}
        <Button
          onClick={(e) => {
            confirmation("CLOSE_LIVE", false);
          }}
          className={classes.Button + " " + classes.ButtonEnd }
        >
          {appContext.userType === "tutor" ? "End Live Class" : "End Live"}
        </Button>
      </React.Fragment>
    </div>
  );
}

//   //   appContext.modal && appContext.modal.type == "CLOSE_LIVE" && (
//   //   <CloseModal
//   //     hidden={appContext.modal && appContext.modal.hidden}
//   //     handleSuccess={endLiveClass}
//   //     handleClose={confirmation}
//   //     toggle={() => confirmation()}
//   //     showLeaving={appContext.leaveClass}
//   //     appContextData={appContext}
//   //   />
//   // )
// }
// {
//   //   appContext.modal && appContext.modal.type == "DYNAMIC_KEY_EXPIRED" && (
//   //   <LeaveForced
//   //     text={`Your class has been expired "${appContext.sessionData.title}"`}
//   //     hidden={appContext.modal && appContext.modal.hidden}
//   //     handleSuccess={endLiveClass}
//   //     toggle={endLiveClass}
//   //     showLeaving={appContext.leaveClass}
//   //     appContextData={appContext}
//   //   />
//   // )
// }
// {
//   //   appContext.modal && appContext.modal.type == "CLIENT_BANNED" && (
//   //   <LeaveForced
//   //     text={`The live class has been ended`}
//   //     hidden={appContext.modal && appContext.modal.hidden}
//   //     handleSuccess={endLiveClass}
//   //     toggle={endLiveClass}
//   //     showLeaving={appContext.leaveClass}
//   //     appContextData={appContext}
//   //   />
//   // )
// }
// {
//   //   appContext.modal && appContext.modal.type == "NotAllowedError" && (
//   //   <LeaveForced
//   //     text={"Unable to Access"}
//   //     body={
//   //       <div>
//   //         <p>We are unable to access your device please check :</p>
//   //         <p>1. Microphone & Camera Permissions</p>
//   //         <p>
//   //           2. Please check if any another application is not using your
//   //           microphone or camera concurrently
//   //         </p>
//   //         <p>3. Your camera drivers and web browser is updated</p>
//   //         <p>4. Reload after changing permissions</p>
//   //       </div>
//   //     }
//   //     hidden={appContext.modal && appContext.modal.hidden}
//   //     handleSuccess={() => confirmation()}
//   //     toggle={() => confirmation()}
//   //     showLeaving={appContext.leaveClass}
//   //     appContextData={appContext}
//   //   />
//   // )
// }