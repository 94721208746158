import React, { createContext, Component } from "react";
import RtmClient from "../utils/rtm-client";

export const AppContext = createContext({});

// Provider
export default class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionLoaded: false,
      isVideoSharing: false,
      members: {}, // key value pair of joined members user attribute
      membersCount: 0,
      userType: null,
      sessionType: null,
      twoWayEnabled: null,
      studentInvitation: {},
      goingLiveTime: 5,
      raiseHandSettings: true,
      chatRepliesSettings: true,
    };

    this.setContext = this.setContext.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  setContext(obj) {
    // console.log({ obj });
    this.setState(obj, () => {
      // console.log("updatedContext", this.state);
    });
  }

  getUserInfo() {
    let { persistedData } = this.state;
    return {
      id: (persistedData && persistedData.user && persistedData.user.id) ||
        "NOT_AVAILABLE",
      name: (persistedData && persistedData.user && persistedData.user.name) ||
        "NOT_AVAILABLE",
      imageUrl: (persistedData && persistedData.user && persistedData.user.imageUrl) ||
        "NOT_AVAILABLE",
      platform: "web",
      isTutor: persistedData && persistedData.user && persistedData.user.type === 3 ?
        "1" : "0",
    };
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setContext: this.setContext,
          getUserInfo: this.getUserInfo,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}