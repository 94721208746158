import React, { useState, useEffect, useContext } from "react";
import { Loader } from "semantic-ui-react";
import "./App.css";
import classes from "./Live.module.css";
// import { Tab, Grid, Menu, Icon } from "semantic-ui-react";
// import { startSession } from "./apis";
import { ToastContainer, toast } from "react-toastify";
import { AppContext } from "./context/AppContext";
// import StudentScreen from "./screens/StudentScreen";
import VideoPanel from "./containers/VideoPanel/VideoPanel";
import Controls from "./containers/Controls/Controls";
import SessionDetails from "./containers/VideoPanel/SessionDetails";
import "react-toastify/dist/ReactToastify.css";

import Chat from "./containers/chat/index";
import staticData from "./staticData";

import { startSession, leaveChannel, getStudentsList } from "./apis";
import GoingLiveScreen from "./containers/goingLiveScreen";
toast.configure({
  autoClose: 5000,
  draggable: false,
  //etc you get the idea
});

function App() {
  // const [micChecking, updateMicChecking] = useState(true);
  const postMessageHandler = (data) => {
    console.log(data && data.data, "+++++++data");
    data = JSON.parse(data.data);
    // data = staticData;
    // if (data.sessionData.liveSessionId) {
    //   localStorage.setItem("sessionData", JSON.stringify(data.sessionData));
    //   localStorage.setItem("persistedData", JSON.stringify(data.persistedData));
    // } else {
    //   data.sessionData =
    //     Boolean(localStorage.getItem("sessionData")) &&
    //     JSON.parse(localStorage.getItem("sessionData"));
    //   data.persistedData =
    //     Boolean(localStorage.getItem("sessionData")) &&
    //     JSON.parse(localStorage.getItem("sessionData"));
    // }

    // startSession(data.sessionData, data.persistedData);
    console.log(data);
    console.log("message event on load");
    appContext.setContext({
      sessionLoaded: true,
      sessionData: data.sessionData,
      persistedData: data.persistedData,
      userType: data &&
        data.persistedData &&
        data.persistedData.user &&
        data.persistedData.user.type === 3 ?
        "tutor" : data &&
        data.persistedData &&
        data.persistedData.user &&
        data.persistedData.user.type === 1 ?
        "student" : null,
    });

    // setPersistedData(data.persistedData);
    // setSessionData(data.sessionData);
    // load session data to context here
  };

  const appContext = useContext(AppContext);
  // let [sessionData, setSessionData] = useState(null);
  // let [persistedData, setPersistedData] = useState(null);

  // adding listener in hook
  useEffect(() => {
    // faking data load
    window.top.postMessage("SET_LIVE_IFRAME");
    window.addEventListener("message", postMessageHandler);
    document.addEventListener("message", postMessageHandler);
    let { sessionData, persistedData } = staticData;
    // !goingLiveTime && startSession(sessionData, persistedData);
  }, []);

  if (
    !appContext.sessionLoaded ||
    !appContext.sessionData ||
    Object.keys(appContext.sessionData) < 2 ||
    !appContext.persistedData ||
    appContext.showLoader
  )
    return <Loader active> Loading</Loader>;

  let { goingLiveTime } = appContext;
  return (
    <div className={classes.liveContainer}>
      <ToastContainer
        hideProgressBar={true}
        position="top-left"
        toastClassName="toastDark"
      />
      <div className={classes.chatContainer}>
        <Chat />
      </div>
      <div className={classes.fullScreenVideo}>
        {appContext.sessionData && appContext.persistedData && <VideoPanel />}
      </div>
      {
        <div className={classes.lowerUiBar}>
          <SessionDetails />
          <Controls />
        </div>
      }
    </div>
  )
}

export default App;