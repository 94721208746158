export const BASE_URL =
  // "http://dev-testing.classplus.co";
  // &&
  // "https://wl-apip.classplusapp.com";
  //  &&
  // "https://apip.classplusapp.com";
  "https://api.classplusapp.com";
//  &&
// "http://dev-testing.classplus.co";
//  &&
// " https://wl-apip.classplus.co";

// export const LIVE_SOCKET_URL = "https://livepro.teach-r.com:4995"; // Staging
export const LIVE_SOCKET_URL = "https://livebe.teach-r.com:4996"; // PROD

export const AMS_SERVER_URL = "ams.teach-r.com:5443";
export const AMS_CDN_URL = "https://d1oen61hkzag51.cloudfront.net";
export const LIVE_SERVER_URL = "https://livepro.teach-r.com:4443";
export const LIVE_SERVER_SECRET = "--k-trpn-L-";

// export const LIVE_SERVER_SECRET = "pa88w0rd";
export const LIVE_SERVER_BITRATE = 850;