import React, { useContext, useEffect } from "react";
import { Image, Placeholder, Icon, Button } from "semantic-ui-react";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { AppContext } from "../context/AppContext";

export default function RoomUserCard(props) {
  let context = useContext(AppContext);
  let { member, tutorId, memberId } = props;
  let { videoClient, sessionData, userType, setContext } = context;

  // console.log("RoomUserCard ::: userType : ", userType);

  const acceptInvitation = () => {
    console.log("Accepted Invitation");
    member.invitationStatus = "ACCEPTED";
    setContext({
      member: member
    })
    videoClient.socket.emit("student_events", {
      userId: member.id,
      sessionId: sessionData.liveSessionId,
      event: "ACCEPTED"
    })
  };

  const cancelInvitaion = () => {
    console.log("Cancelled Invitation");
    member.invitationStatus = "REJECTED";
    setContext({
      member: member
    })
    videoClient.socket.emit("student_events", {
      userId: member.id,
      sessionId: sessionData.liveSessionId,
      event: "REJECTED"
    })
  };

  const endInvitationConnection = () => {
    console.log("Ended Invitation");
    member.invitationStatus = "ENDED";
    setContext({
      member: member
    })
    videoClient.socket.emit("student_events", {
      userId: member.id,
      sessionId: sessionData.liveSessionId,
      event: "ENDED"
    })
  };

  // Event hadnlers
  const remoteInviteAcceptedHandler = () => {
    console.log("Invite Accept Handler");
  };

  const remoteInviteCancelHandler = () => {
    console.log("Invite Cancel Handler");
  };

  const remoteInviteRemoveHandler = () => {
    console.log("Invite Remove Handler");
  };

  const remoteInviteFailureHandler = () => {
    console.log("Invite Failure Handler");
  };

  useEffect(() => {
    // console.log("room user card");
  }, []);

  if (!member)
    return (
      <li key={memberId}>
        <Placeholder className="roomUserPlaceholder">
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </li>
    );

  let { id, name, imageUrl, platform } = member;

  let image =
    imageUrl === "NOT_AVAILABLE" || !imageUrl ? (
      <Avatar name={name} className="avatar" />
    ) : (
      <Image className="avatar" avatar src={imageUrl} />
    );

  return (
    <li className="roomUser" key={id}>
      {image}
      <div className="detailContainer">
        <div className="userName">
          <div>
            <div>
              {name}{" "}
              {member.invitationStatus === "CONNECTED" && (
                <p>is asking question...</p>
              )}
            </div>
            {member.invitationStatus === "CONNECTED" && (
              <Icon 
                color="grey"
                name={context.sessionData.showVideo ? "video" : "microphone"}
                size="small"
              />
            )}
          </div>
          <div className="flexCenter ">
            {
              member.invitationStatus === "PENDING" &&
              !member.isTutor && (
                <Button.Group basic size="small" className="mx10">
                  <Button icon onClick={acceptInvitation}>
                    <Icon name="check" color="green" />
                  </Button>
                  <Button icon onClick={cancelInvitaion}>
                    <Icon name="close" color="red" />
                  </Button>
                </Button.Group>
              )
            }
            {(member.invitationStatus === "ACCEPTED" || member.invitationStatus === "CONNECTED") && (
              <Button icon basic onClick={endInvitationConnection}>
                <Icon name="close" color="red" />
              </Button>
            )}

            {tutorId === member.id && (
              <span className="badge success mx10"> Admin </span>
            )}
            {platform === "web" ? (
              <Icon name="computer" size="small" />
            ) : platform === "mobile" ? (
              <Icon name="mobile alternate" size="small" />
            ) : null}

            {member.joinedAt && member.invitationStatus !== "ACCEPTED" && <span className="badge new">New</span>}
          </div>
        </div>
        {/* <div className="infoText"> is asking question... </div> */}
      </div>
    </li>
  );
}