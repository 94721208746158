import { AppContext } from "../../context/AppContext";
import { LIVE_SOCKET_URL, BASE_URL, AMS_SERVER_URL, AMS_CDN_URL, LIVE_SERVER_BITRATE } from "../../appConfig";
import React, { useContext, useMemo, useEffect } from "react";
import moment from 'moment';
import EventEmitter from "events";
import dashjs from "dashjs";
import { startSession, getVideoToken, getSessionDetail, startRecording, stopRecording, leaveChannel } from "../../apis";
import WebRTCAdaptor from '../../utils/webrtc_adaptor';
import { toast } from "react-toastify";

import {
  isSafari,
  isCompatibleChrome,
  isFirefox,
} from "./../../utils/rtcUtils";
import io from 'socket.io-client';

let OpenVidu = window.OpenVidu;
let jwplayer = window.jwplayer;
let location = window.location;

export default class VideoHandler extends EventEmitter {

  userID = "";
  _logined = false;
  isLive = false;
  sessionData = {};
  persistedData = {};
  session = {};
  devices = {
    cameras: [],
    mics: [],
  };
  twoWayEnabled = false;
  userType = "";
  OV = undefined;
  publisher = undefined;
  videoSession = undefined;
  cameraVideoTrack = undefined;
  screenVideoTrack = undefined;
  socket = undefined;

  sessionType = "ams";

  constructor() {
    super();
  }

  init = (sessionData, persistedData, userType) => {
    this.sessionData = sessionData;
    this.persistedData = persistedData;
    this.userType = userType;

    if (localStorage.getItem("sessionId")) {
      if (this.sessionData.liveSessionId != localStorage.getItem("sessionId")) {
        localStorage.removeItem("videoDisabled");
        localStorage.removeItem("audioDisabled");
      }
    }

    jwplayer.key = "maVKnN8YJiCGaWsCy6xa5KoV45doYy/wTBqv49tFdVg=";

    let liveSessionType = this.sessionData.liveSessionType;
    console.log(liveSessionType, "^^^^^^^liveSessionType")
    if (liveSessionType) {
      this.sessionType = liveSessionType;
    }

    this.initSocket();
    this.getDevices();
    var self = this;
    if (userType != 'tutor') {
      setTimeout(function() {
        self.socket.emit("get_students", { sessionId: self.sessionData.liveSessionId + "" });
      }, 3000)
    }
  };
  isSocketConnected = false;
  initSocket = () => {
    var self = this;
    this.socket = io(LIVE_SOCKET_URL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      jsonp: false,
      transports: ["websocket"]
    });
    // console.log("VideoHandler :: socket : ", socket);
    this.socket.on("connect", function() {
      self.isSocketConnected = true;
      if (!self.sessionDisconnected) {
        console.log("Socket connected for first time");

        if (self.userType === 'tutor') {
          if (self.sessionType == "ams") {
            self.emit("twoway-enabled", true);
            setTimeout(function() {
              self.initAMSSession();
            }, 500)
          } else {
            self.initViduSession();
          }
        }
      } else {
        console.log("Socket reconnected after disconnect");
        if (self.userType === 'tutor') {
          toast.dismiss();
          toast.success("Network reconnected and resuming session ..!");
          setTimeout(function() {
            self.socket.emit("join_room", { sessionId: self.sessionData.liveSessionId });
            self.initAMSSession();
          }, 2000);
        } else {
          self.sessionDisconnected = false;
          self.joinSocketSession();
        }
      }
    });
    this.socket.on("disconnect", function() {
      if (self.userType != "tutor") {
        self.sessionDisconnected = true;
      }
      self.isSocketConnected = false;
      console.log("Socket disconnected .. ");
      self.networkDisconnected();
    });
    // setInterval(function() {
    //   if ( self.isSocketConnected) {
    //     console.log("Socket is in connected state .. ")
    //   } else {
    //     console.log("Socket is in disconnected state .. ")
    //   }
    // }, 1000);

    this.socket.on('chat_msgs', data => {
      // console.log("VideoHandler ::: chat_msgs :: data : ", data);
      setTimeout(function() {
        // as of now, we are not showing previous chat mgs 
        // self.emit("chat_msgs", data);
      }, 2000);
    });

    if (this.userType === "student") {
      this.socket.on('class_started', data => {
        console.log("VideoHandler ::: class_started :: data : ", data);
        if (data.sessionId == self.sessionData.liveSessionId) {
          if (data.sessionType == "free") {
            data.sessionType = "ams";
          }
          self.sessionType = data.sessionType;
          self.sessionData.streamKey = data.streamKey;
          self.emit("session-type", data.sessionType);
          if (data.sessionType == "ams") {
            self.joinAMSSession(data);
          } else if (data.sessionType == "vidu") {
            self.joinViduSession(data.streamKey);
          }
        }
      });
      this.socket.on('class_ended', data => {
        console.log("VideoHandler ::: class_ended :: data : ", data);
        if (data.sessionId == self.sessionData.liveSessionId) {
          if (data.expected) {
            self.endSession();
          } else {
            toast.error("Looks like tutor is facing connectivity issues, please wait to reconnect ..!", { autoClose: 1060 * 1000 })
            setTimeout(function() {
              self.endSession();
            }, 10 * 60 * 1000)
          }
        }
      });
      this.socket.on("student_events", function(data) {
        if (data.sessionId == self.sessionData.liveSessionId) {
          if (data.userId === self.persistedData.user.id && data.event == 'ACCEPTED') {
            if (self.sessionType == "ams") {
              var player = jwplayer("player");
              player.pause();
              var playerNode = document.getElementById("player");
              if (playerNode) {
                if (playerNode.hasChildNodes()) {
                  while (playerNode.firstChild) {
                    playerNode.removeChild(playerNode.lastChild);
                  }
                }
              }
              setTimeout(function() {
                self.startStudentAmsAudio();
              }, 500)
            } else if (self.sessionType == "vidu") {
              self.startStudentViduAudio();
            }
          } else if (data.userId === self.persistedData.user.id && data.event == 'ENDED') {
            if (self.sessionType == "ams") {
              self.stopStudentAmsAudio();
            } else if (self.sessionType == "vidu") {
              self.stopStudentViduAudio();
            }
          } else if (data.eventType) {
            if (data.eventType == 108) {
              toast.dismiss();
              if (data.isAllowed) {
                toast.error("Tutor's mic is muted", { autoClose: 1000 * 1000 });
              } else {
                toast.success("Tutor's mic is unmuted");
              }
            } else if (data.eventType == 106) {
              toast.dismiss();
              if (data.isAllowed) {
                toast.error("Tutor's video is disabled", { autoClose: 1000 * 1000 });
              } else {
                toast.success("Tutor's video is enabled");
              }
            }
          }
        }
      })
    } else {
      this.socket.on("student_events", function(data) {
        if (data.sessionId === self.sessionData.liveSessionId && data.event == 'CONNECTED') {
          if (self.sessionType == "ams") {
            self.connectStudentAmsAudio(data.userId);
          }
        }
      })
    }
    this.socket.on('session_students', students => {
      console.log("VideoHandler ::: session_students :: students : ", students);
      setTimeout(function() {
        self.emit("session_students", students)
      }, 2000);
    });

    this.joinSocketSession();
  }
  joinSocketSession = () => {
    this.socket.emit("join_session", {
      sessionId: this.sessionData.liveSessionId + "",
      isTutor: this.userType == 'tutor' ? true : false,
      uid: this.persistedData.user.id,
      userId: this.persistedData.user.id,
      name: this.persistedData.user.name,
      imageUrl: this.persistedData.user.imageUrl,
      joinedAt: new Date(),
      platform: "Web"
    })
  }
  endSession() {
    if (this.sessionType == "ams") {
      this.endAMSSession(this.userType);
    } else if (this.sessionType == "vidu") {
      this.endViduSession(this.userType);
    }
  }
  sendChat = (chat) => {
    // chat.sessionId = this.sessionData.liveSessionId;
    console.log("VideoHandler ::: chat object : ", chat);
    this.socket.emit("send_msg", chat);
  }

  // ############### Start of OpenVidu #############
  shareViduScreenChanged = async (isScreenshared) => {
    var self = this;
    console.log("VideoHandler ::: shareScreen :: isScreenshared : ", isScreenshared);
    if (isScreenshared) {
      let displayMediaOptions = {
        video: {
          cursor: "always"
        },
        audio: false
      };
      var mediaObj = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
      let screenVideoTrack = mediaObj.getVideoTracks()[0];
      console.log("VideoHandler ::: shareScreen :: screenVideoTrack : ", screenVideoTrack);

      if (this.publisher) {
        let cameraStream = this.publisher.stream;
        if (cameraStream && cameraStream.mediaStream) {
          var cameraVideoTrack = cameraStream.getMediaStream().getVideoTracks()[0];
          if (cameraVideoTrack) {
            cameraVideoTrack.stop();
          }
        }

        this.publisher.replaceTrack(screenVideoTrack)
          .then(() => console.log('VideoHandler ::: shareScreen :: New track is being published'))
          .catch(error => console.error('VideoHandler ::: shareScreen :: Error replacing track'));

        this.screenVideoTrack = screenVideoTrack;
        screenVideoTrack.addEventListener('ended', () => {
          console.log('VideoHandler ::: shareScreen :: User pressed the "Stop sharing" button');
          self.stopViduScreenShare();
        });
      }
    } else {
      this.stopViduScreenShare();
    }
  }
  stopViduScreenShare = async () => {
    this.screenVideoTrack && this.screenVideoTrack.stop();

    var mediaObj = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    let newVideoTrack = mediaObj.getVideoTracks()[0];
    this.publisher.replaceTrack(newVideoTrack)
      .then(() => console.log('VideoHandler ::: shareScreen :: video track is being published'))
      .catch(error => console.error('VideoHandler ::: shareScreen :: Error replacing video track', error));
  }
  initViduSession = () => {
    let self = this;
    this.OV = new OpenVidu();
    this.OV.enableProdMode();
    this.videoSession = this.OV.initSession();

    // On every new Stream received...
    this.videoSession.on('streamCreated', (event) => {
      // Subscribe to the Stream to receive it. 
      let subscriber = self.videoSession.subscribe(event.stream, "mainVideo");
    });

    // On every Stream destroyed...
    this.videoSession.on('streamDestroyed', (event) => {
      event.preventDefault();
      console.log("VideoHandler ::: streamDestroyed :: event : ", event);
      var remoteVideo = document.getElementById("remote-video-" + event.stream.inboundStreamOpts.id);
      remoteVideo.parentNode.removeChild(remoteVideo);
    });

    if (!this.sessionData.streamKey) {
      var streamKey = moment().format("YYYYMMDDHHmmss") + "-" + this.persistedData.organization.id + "-" + this.sessionData.liveSessionId + "-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      // streamKey = "session-cp-test";
      this.sessionData.streamKey = streamKey
    }
    getVideoToken(this.sessionData.streamKey)
      .then((token) => {
        console.log("^^^^^^^^^^^VideoHandler token :: ", token);
        this.videoSession.connect(token, { clientData: "sam" })
          .then(() => {
            console.log("^^^^^^^^^^^VideoHandler2  !!!!!:: ", token);
            // Init a publisher passing undefined as targetElement 
            this.publisher = this.OV.initPublisher("mainVideo", {
              audioSource: undefined, // The source of audio. If undefined default microphone
              videoSource: undefined, // The source of video. If undefined default webcam
              publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
              publishVideo: true, // Whether you want to start publishing with your video enabled or not
              resolution: '1280x720', // The resolution of your video
              frameRate: 15, // The frame rate of your video
              insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
              mirror: false // Whether to mirror your local video or not
            });

            this.publisher.on('streamPlaying', function(event) {
              self.emit("session-connected", event);
              console.log("videoHandler ::: streamPlaying :: event : ", event);
              self.socket.emit("start_class", {
                sessionType: self.sessionType,
                sessionId: self.sessionData.liveSessionId,
                streamKey: self.sessionData.streamKey,
              })

              // startRecording({
              //   sessionId: self.sessionData.liveSessionId,
              //   orgId: self.persistedData.organization.id,
              //   streamKey: self.sessionData.streamKey
              // }).then(res => {
              //   console.log("VideoHandler ::: startRecording :: res : ", res);
              // })
              startSession(self.sessionData, self.persistedData);
            });
            // Publish your stream ---
            this.videoSession.publish(this.publisher);
          })
      })
  }

  joinViduSession = (streamKey) => {
    let self = this;
    this.OV = new OpenVidu();
    this.OV.enableProdMode();
    this.videoSession = this.OV.initSession();
    // On every new Stream received...
    this.videoSession.on('streamCreated', (event) => {
      // Subscribe to the Stream to receive it. 
      let subscriber = self.videoSession.subscribe(event.stream, "mainVideo");
      console.log("joinViduSession ::: streamCreated : event : ", event)
      //We use an auxiliar array to push the new stream
      subscriber.on('videoElementCreated', event => {
        console.log("joinViduSession ::: videoElementCreated !!")
      });
      subscriber.on('streamPlaying', function(event) {
        console.log("joinViduSession ::: streamPlaying !!");
        self.emit("session-connected", event);
      });
    });

    // On every Stream destroyed...
    this.videoSession.on('streamDestroyed', (event) => {
      event.preventDefault();
      // Remove the stream from 'subscribers' array
    });

    // var streamKey = "20201222113029-41654-501-QW5DK";
    streamKey = "20210120081758-72390-845-NmdwS";
    getVideoToken(streamKey)
      .then((token) => {
        console.log("VideoHandler token :: ", token);
        this.videoSession.connect(token, { clientData: "student" })
          .then((res) => {
            console.log("VideoHandler :: joinViduSession :: connected : res ", res)
          });
      })
  }

  endViduSession = (userType) => {
    if (this.videoSession) {
      if (userType === 'tutor') {
        this.videoSession.unpublish(this.publisher);
        this.socket.emit("end_class", {
          sessionId: this.sessionData.liveSessionId,
          streamKey: this.sessionData.streamKey,
        })
        // stopRecording({
        //   sessionId: this.sessionData.liveSessionId,
        //   orgId: this.persistedData.organization.id,
        //   streamKey: this.sessionData.streamKey
        // }).then(res => {
        //   console.log("VideoHandler ::: stopRecording :: res : ", res);
        // })
        leaveChannel(this.sessionData, this.persistedData, () => {
          toast.success("Live session ended");
        });
      } else {
        leaveChannel(this.sessionData, this.persistedData, () => {
          toast.success("Live session ended");
        });
      }
      this.videoSession.disconnect();
      this.emit("session-ended");
    }
  }

  stopStudentViduAudio = () => {
    this.videoSession.unpublish(this.publisher);
  }

  startStudentViduAudio = () => {
    var self = this;
    if (this.OV) {
      this.publisher = this.OV.initPublisher("mainVideo", {
        audioSource: undefined, // The source of audio. If undefined default microphone
        videoSource: undefined, // The source of video. If undefined default webcam
        publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
        publishVideo: false, // Whether you want to start publishing with your video enabled or not
        resolution: '1280x720', // The resolution of your video
        frameRate: 15, // The frame rate of your video
        insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
        mirror: false // Whether to mirror your local video or not
      });

      this.publisher.on('streamPlaying', function(event) {
        console.log("VideoHandler ::: startStudentViduAudio :: streamPlaying !!")
        self.socket.emit("student_events", {
          userId: self.persistedData.user.id,
          sessionId: self.sessionData.liveSessionId,
          event: "CONNECTED"
        })
      });
      // Publish your stream ---
      this.videoSession.publish(this.publisher);
    }
  }

  deviceChanged = async (activeCameraId, activeMicId) => {
    if (this.sessionType == "vidu") {
      var mediaObj = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: activeCameraId } },
        audio: { deviceId: { exact: activeMicId }, echoCancellation: true }
      });
      let newVideoTrack = mediaObj.getVideoTracks()[0];
      this.publisher.replaceTrack(newVideoTrack)
        .then(() => console.log('VideoHandler ::: deviceChanged :: video track is being published'))
        .catch(error => console.error('VideoHandler ::: deviceChanged :: Error replacing video track', error));
    } else if (this.sessionType == "ams" && this.isLive) {
      if (activeCameraId) {
        this.webRTCAdaptor.switchVideoCameraCapture(this.sessionData.streamKey, activeCameraId);
      }
      if (activeMicId) {
        this.webRTCAdaptor.switchAudioInputSource(this.sessionData.streamKey, activeMicId);
      }
    }
  }
  // ############### End of OpenVidu #############


  // ############### Start of AMS #############
  webRTCAdaptor;
  tutorWebRTCAdaptor;
  studentWebRTCAdaptor;
  autoRepublishIntervalJob;
  checkAndRepublishIfRequired = () => {
    var streamId = this.sessionData.streamKey;

    var iceState = this.webRTCAdaptor.iceConnectionState(streamId);
    // console.log("Ice state checked = " + iceState);

    if (iceState == null || iceState == "failed" || iceState == "disconnected") {
      this.webRTCAdaptor.stop(streamId);
      this.webRTCAdaptor.closePeerConnection(streamId);
      this.webRTCAdaptor.closeWebSocket();
      this.initAMSSession();
    }
  }

  getSessionDetails = (data) => {
    var self = this;
    return new Promise((resolve, reject) => {
      self.socket.off("session_details");
      self.socket.on("session_details", function(session) {
        resolve(session);
      })
      self.socket.emit("get_session_details", data);
    })
  }
  initAMSSession = async () => {
    if (!this.isLive && this.isSocketConnected) {
      var self = this;
      var pc_config = {
        'iceServers': [{
          // 'urls': 'stun:stun1.l.google.com:19302'
          'urls': 'stun:stun.teach-r.com:3478'
        }]
      };

      var sdpConstraints = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
      };

      var mediaConstraints = {
        video: { width: 1280, height: 720 },
        audio: true
      };
      if (localStorage.getItem("activeCameraId")) {
        mediaConstraints.video.deviceId = localStorage.getItem("activeCameraId");
      }
      if (localStorage.getItem("activeMicId")) {
        mediaConstraints.audio = { deviceId: localStorage.getItem("activeMicId") };
      }

      var liveServerUrl = AMS_SERVER_URL;
      var liveBitrate = LIVE_SERVER_BITRATE;
      var session;
      try {
        session = await this.getSessionDetails({
          orgId: this.persistedData.organization.id,
          sessionId: this.sessionData.liveSessionId,
          title: this.sessionData.title,
          platform: "Web"
        });
      } catch (e) {
        console.log("initAMSSession ::: getSessionDetails :: exception : ", e)
        setTimeout(function() {
          self.initAMSSession();
        }, 1000);
        return;
      }
      if (session && session.streamKey && session.serverUrl) {
        this.sessionData.streamKey = session.streamKey;
        liveServerUrl = session.serverUrl || liveServerUrl;
        liveBitrate = session.bitrate || liveBitrate;
      }

      var path = liveServerUrl + "/LiveApp/websocket?rtmpForward=false";
      var websocketURL = "wss://" + path;

      if (!this.sessionData.streamKey) {
        var streamKey = moment().format("YYYYMMDDHHmmss") + "-" + this.persistedData.organization.id + "-" + this.sessionData.liveSessionId + "-" + Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
        // streamKey = "session-cp-test";
        this.sessionData.streamKey = streamKey;
      }
      if (!this.sessionDisconnected) {
        self.emit("session-connected", {});
      }

      var webRTCAdaptor = null,
        streamId = this.sessionData.streamKey || "ams-testing";

      webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        localVideoId: "tutorVideo",
        // debug: true,
        bandwidth: liveBitrate,
        callback: (info, obj) => {
          if (info == "initialized") {
            console.log("initialized");
            webRTCAdaptor.publish(streamId, null);

          } else if (info == "publish_started") {
            //stream is being published
            console.log("publish started");
            if (self.autoRepublishIntervalJob == null) {
              self.autoRepublishIntervalJob = setInterval(() => {
                self.checkAndRepublishIfRequired();
              }, 3000);
            }
            // webRTCAdaptor.enableStats(obj.streamId);
            startSession(self.sessionData, self.persistedData);
            self.isLive = true;
            setTimeout(function() {
              self.socket.emit("start_class", {
                sessionType: self.sessionType,
                serverUrl: AMS_SERVER_URL.split(":")[0],
                sessionId: self.sessionData.liveSessionId,
                streamKey: self.sessionData.streamKey,
              })
            }, 1000);
            self.sessionDisconnected = false;

            // check for mute audio n video state and apply the same to live stream
            self.emit("restore-audio-video-controls", {});

            toast.dismiss();
            toast.info("You are now Live!");

          } else if (info == "publish_finished") {
            //stream is being finished
            console.log("publish finished");
          } else if (info == "browser_screen_share_supported") {
            console.log("browser screen share supported");
          } else if (info == "screen_share_stopped") {
            //choose the first video source. It may not be correct for all cases. 
            console.log("screen share stopped");
            self.emit("screenshare-cancelled", {});
          } else if (info == "closed") {
            //console.log("Connection closed");
            if (typeof obj != "undefined") {
              console.log("Connecton closed: " + JSON.stringify(obj));
            }
            self.emit("screenshare-cancelled", {});
          } else if (info == "refreshConnection") {
            self.checkAndRepublishIfRequired();
          } else if (info == "ice_connection_state_changed") {
            // console.log("iceConnectionState Changed: ", JSON.stringify(obj));
          } else if (info == "updated_stats") {
            // console.log("videoHandler ::: updated_stats :: network stats :  averageOutgoingBitrate - ", obj.averageOutgoingBitrate);
            // console.log("Average outgoing bitrate " + obj.averageOutgoingBitrate + " kbits/sec" +
            //   " Current outgoing bitrate: " + obj.currentOutgoingBitrate + " kbits/sec" +
            //   " video source width: " + obj.resWidth + " video source height: " + obj.resHeight +
            //   "frame width: " + obj.frameWidth + " frame height: " + obj.frameHeight +
            //   " video packetLost: " + obj.videoPacketsLost + " audio packetsLost: " + obj.audioPacketsLost +
            //   " video RTT: " + obj.videoRoundTripTime + " audio RTT: " + obj.audioRoundTripTime +
            //   " video jitter: " + obj.videoJitter + " audio jitter: " + obj.audioJitter);

          } else if (info == "data_received") {
            // console.log("Data received: " + obj.event.data + " type: " + obj.event.type + " for stream: " + obj.streamId);
          } else {
            // console.log(info + " notification received");
          }
        },
        callbackError: function(error, message) {
          console.log("error callback: " + JSON.stringify(error));
          if (error == "ScreenSharePermissionDenied") {
            self.emit("screenshare-cancelled", {});
          } else if (error == "WebSocketNotConnected") {
            self.networkDisconnected();
          } else if (error == "publishTimeoutError") {
            self.sessionDisconnected = false;
            self.networkDisconnected();
            setTimeout(function() {
              self.initAMSSession();
            }, 3000);
          } else if (error == "NotReadableError") {
            toast.dismiss();
            toast.error("Camera device is in use by other software, please stop Camera usage and restart the session.", { autoClose: 1000 * 1000 });
          }
        }
      });

      this.webRTCAdaptor = webRTCAdaptor;
    }
  }
  networkDisconnected() {
    if (!this.sessionDisconnected && this.userType == "tutor") {
      this.sessionDisconnected = true;
      if (this.autoRepublishIntervalJob != null) {
        clearInterval(this.autoRepublishIntervalJob);
        this.autoRepublishIntervalJob = null;
      }
      if (this.webRTCAdaptor) {
        this.webRTCAdaptor.stop(this.sessionData.streamKey);
      }
      this.isLive = false;
      toast.error("Network disconnected, waiting to reconnect ...!", { autoClose: 1000 * 1000 });
    }
  }

  tutorVideoPlaying = false;
  sessionDisconnected = false;
  player;
  streamData;
  showedDashError = false;
  joinAMSSession = (data) => {
    if (data && data.streamKey) {
      var self = this;
      this.streamData = data;
      // var player = jwplayer("player");
      // player.setup({
      //   // file: "https://" + AMS_SERVER_URL + "/LiveApp/streams/" + streamKey + ".m3u8",
      //   file: AMS_CDN_URL + "/streams/" + streamKey + ".m3u8",
      //   primary: "html5",
      //   skin: "bekle",
      //   playbackRateControls: [0.75, 1, 1.25, 1.5, 2],
      //   autostart: true,
      // });
      // player.on("ready", function() {
      //   self.tutorVideoPlaying = true;
      //   self.emit("session-connected", {});
      // })


      if (self.tutorVideoPlaying && self.player) {
        self.player.destroy();
        self.player = null;
      }

      var url = (data.cdnUrl || AMS_CDN_URL) + "/streams/" + data.streamKey + "/" + data.streamKey + ".mpd";
      // var url = (AMS_CDN_URL) + "/streams/" + data.streamKey + "/" + data.streamKey + ".mpd";
      self.player = dashjs.MediaPlayer().create();
      self.player.updateSettings({
        streaming: {
          lowLatencyEnabled: true,
          liveDelay: 3,
          liveCatchup: {
            minDrift: 0.02,
            maxDrift: 0,
            playbackRate: 0.5,
            latencyThreshold: 60
          }
        }
      });
      self.player.initialize(document.querySelector("#player"), url, false);
      self.player.on(dashjs.MediaPlayer.events['CAN_PLAY'], function() {
        self.player.play();
        toast.dismiss();
      })
      self.player.on('error', function(e) {
        console.log("DashPlayer :: error : ", e);
        setTimeout(function() {
          self.joinAMSSession(self.streamData);
        }, 6000);
        if (!self.showedDashError) {
          toast.info("Trying to connect in a moment ...");
          self.showedDashError = true;
        }
      });

      if (!self.tutorVideoPlaying) {
        self.tutorVideoPlaying = true;
        self.emit("session-connected", {});
        startSession(self.sessionData, self.persistedData);
      }
    }
  }
  endAMSSession = (userType) => {
    if (userType === 'tutor') {
      localStorage.removeItem("audioDisabled");
      localStorage.removeItem("videoDisabled");
      if (this.autoRepublishIntervalJob != null) {
        clearInterval(this.autoRepublishIntervalJob);
        this.autoRepublishIntervalJob = null;
      }
      this.webRTCAdaptor && this.webRTCAdaptor.stop(this.sessionData.streamKey);
      this.socket.emit("end_class", {
        sessionId: this.sessionData.liveSessionId + "",
        streamKey: this.sessionData.streamKey,
      })
      leaveChannel(this.sessionData, this.persistedData, () => {
        toast.success("Live session ended");
      });
      // stopRecording({
      //   sessionId: this.sessionData.liveSessionId,
      //   orgId: this.persistedData.organization.id,
      //   streamKey: this.sessionData.streamKey
      // }).then(res => {
      //   console.log("VideoHandler ::: stopRecording :: res : ", res);
      // })
    } else {
      leaveChannel(this.sessionData, this.persistedData, () => {
        toast.success("Live session ended");
      });
    }

    this.emit("session-ended");
  }
  shareAmsScreenChanged = (isScreenshared) => {
    if (this.webRTCAdaptor) {
      if (isScreenshared) {
        this.webRTCAdaptor.switchDesktopCapture(this.sessionData.streamKey);
      } else {
        this.webRTCAdaptor.switchVideoCameraCapture(this.sessionData.streamKey);
      }
    } else {
      toast.info("Session is not live to share screen yet.")
    }
  }

  startStudentAmsAudio() {
    this.emit("twoway-enabled", true);
    var self = this;
    var pc_config = {
      'iceServers': [{
        'urls': 'stun:stun1.l.google.com:19302'
      }]
    };

    var sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false
    };
    var mediaConstraints = {
      video: false,
      audio: true
    };

    var path = AMS_SERVER_URL + "/LiveApp/websocket?rtmpForward=false";
    var websocketURL = "wss://" + path;

    var webRTCAdaptor = null,
      streamId = this.sessionData.streamKey + "-u" + this.persistedData.user.id || "ams-audio-testing";

    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      localVideoId: "audioStream",
      // debug: true,
      bandwidth: 600,
      callback: (info, obj) => {
        if (info == "initialized") {
          console.log("initialized");
          webRTCAdaptor.publish(streamId, null)

        } else if (info == "publish_started") {
          console.log("publish started");
          var audioStreamEle = document.getElementById("audioStream");
          if (audioStreamEle) {
            audioStreamEle.muted = true;
          }
          self.socket.emit("student_events", {
            userId: self.persistedData.user.id + "",
            sessionId: self.sessionData.liveSessionId + "",
            event: "CONNECTED"
          })
        }
      },
      callbackError: function(error, message) {
        console.log("error callback: " + JSON.stringify(error));
      }
    });
    this.webRTCAdaptor = webRTCAdaptor;


    // play remote video with zero lag
    self.tutorWebRTCAdaptor = new WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: {
        video: false,
        audio: false
      },
      peerconnection_config: null,
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      },
      remoteVideoId: "tutorVideo",
      isPlayMode: true,
      debug: false,
      callback: function(info, description) {
        if (info == "initialized") {
          console.log("initialized");
          self.tutorWebRTCAdaptor.getStreamInfo(self.sessionData.streamKey);
        } else if (info == "streamInformation") {
          console.log("stream information");
          self.tutorWebRTCAdaptor.play(self.sessionData.streamKey, null);
        } else if (info == "play_started") {
          console.log("play started");
          var videoEle = document.getElementById("tutorVideo");
          if (videoEle) {
            videoEle.muted = false;
            videoEle.play().then(function(value) {
              console.log("video started playing ... !! ", value);
            }).catch(function(error) {
              console.log("User interaction needed to start playing ", error);
            });
          }
        }
      },
      callbackError: function(error) {
        console.log("remote video error callback: " + JSON.stringify(error));
      }
    });
  }
  stopStudentAmsAudio() {
    this.emit("twoway-enabled", false)
    this.webRTCAdaptor.stop(this.sessionData.streamKey + "-u" + this.persistedData.user.id);
    this.tutorWebRTCAdaptor.stop(this.sessionData.streamKey);
    var self = this;
    setTimeout(function() {
      self.joinAMSSession({ streamKey: self.sessionData.streamKey });
    }, 1000)
  }
  connectStudentAmsAudio(userId) {
    var self = this;
    var path = AMS_SERVER_URL + "/LiveApp/websocket?rtmpForward=false";
    var websocketURL = "wss://" + path;

    self.studentWebRTCAdaptor = new WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: {
        video: false,
        audio: false
      },
      peerconnection_config: null,
      sdp_constraints: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: false
      },
      remoteVideoId: "audioStream",
      isPlayMode: true,
      debug: false,
      callback: function(info, description) {
        if (info == "initialized") {
          console.log("initialized");
          self.studentWebRTCAdaptor.getStreamInfo(self.sessionData.streamKey + "-u" + userId);
        } else if (info == "streamInformation") {
          console.log("stream information");
          self.studentWebRTCAdaptor.play(self.sessionData.streamKey + "-u" + userId, null);
        } else if (info == "play_started") {
          console.log("play started");
          var videoEle = document.getElementById("audioStream");
          if (videoEle) {
            videoEle.controls = true;
            videoEle.muted = false;
            videoEle.play().then(function(value) {
              console.log("audio started playing ... !! ", value);
            }).catch(function(error) {
              console.log("User interaction needed to start playing ", error);
            });
          }
        }
      },
      callbackError: function(error) {
        console.log("remote audio error callback: " + JSON.stringify(error));
      }
    });
  }
  disconnectStudentAmsAudio(userId) {
    this.studentWebRTCAdaptor.stop(this.sessionData.streamKey + "-u" + userId);
  }

  // ############### End of AMS ###############


  getDevices = () => {
    let self = this;
    setTimeout(function() {
      console.log("Checking for Media Devices.....");
      let cameras = [];
      let mics = [];
      navigator.mediaDevices.enumerateDevices().then(function(devices) {
        console.log("List of available media devices :: ", devices);

        devices.forEach((device, i) => {
          if (device.kind === 'videoinput') {
            cameras.push({
              deviceId: device.deviceId,
              label: device.label || 'Camera ' + (i + 1)
            })

            if (localStorage.getItem("activeCameraId")) {
              self.emit("defaultIds", { type: "camera", deviceId: localStorage.getItem("activeCameraId") });
            } else if (cameras.length === 1) {
              self.emit("defaultIds", { type: "camera", deviceId: device.deviceId });
            }
          } else if (device.kind === 'audioinput') {
            mics.push({
              deviceId: device.deviceId,
              label: device.label || 'Mic ' + (i + 1),
            })

            if (localStorage.getItem("activeMicId")) {
              self.emit("defaultIds", { type: "mic", deviceId: localStorage.getItem("activeMicId") });
            } else if (mics.length === 1) {
              self.emit("defaultIds", { type: "mic", deviceId: device.deviceId });
            }
          }
          self.devices.cameras = cameras;
          self.emit("cameraDevicesList", cameras);
          self.devices.mics = mics;
          self.emit("micDevicesList", mics);;
        })
      })
    }, 1000)
  }
}