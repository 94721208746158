import React, { useState, useEffect, useContext } from "react";
import { Checkbox } from "semantic-ui-react";
import { AppContext } from "./../../context/AppContext";
export default function Settings() {
  const appContext = useContext(AppContext);
  const { persistedData, sessionData, videoClient } = appContext;
  let [raiseHandSettings, changeSoundSettings] = useState(
    appContext && appContext.raiseHandSettings
  );
  let [chatRepliesSettings, changeChatSettings] = useState(
    appContext && appContext.chatRepliesSettings
  );
  const disableStudentInputSocket = (chatRepliesSettings) => {
    console.log(
      chatRepliesSettings,
      "++++chatRepliesSettings",
      chatRepliesSettings == true ? false : true
    );
    if (videoClient) {
      videoClient.socket.emit("student_events", {
        userId: persistedData.user.id,
        sessionId: sessionData.liveSessionId,
        event: "",
        eventType: 98,
        isAllowed: chatRepliesSettings,
        userType: 3,
      });
    }
  };
  // <div className="settingsTab__item">
  //       <div className="settingsTab__item--content">
  //         <div className="settingsTab__item--content--head">
  //           Sound on Hand Raise
  //         </div>
  //         <div className="settingsTab__item--content--desc">
  //           Turning OFF will mute the sound you get on a hand raise request
  //         </div>
  //       </div>
  //       <Checkbox
  //         toggle
  //         checked={raiseHandSettings}
  //         onChange={() => {
  //           changeSoundSettings(!raiseHandSettings);
  //           appContext.setContext({ raiseHandSettings: !raiseHandSettings });
  //         }}
  //       />
  //     </div>
  return (
    <div className="settingsTab">
      <div
        className="settingsTab__item"
        style={{ borderTop: "1px solid #dbdbdb" }}
      >
        <div className="settingsTab__item--content">
          <div className="settingsTab__item--content--head">
            Replies in Chat
          </div>
          <div className="settingsTab__item--content--desc">
            Turning OFF will turn off the replies in chat of this live session
          </div>
        </div>
        <Checkbox
          toggle
          checked={chatRepliesSettings}
          onChange={() => {
            changeChatSettings(!chatRepliesSettings);
            appContext.setContext({
              chatRepliesSettings: !chatRepliesSettings,
            });
            disableStudentInputSocket(!chatRepliesSettings);
          }}
        />
      </div>
    </div>
  );
}